@import 'bootstrap/dist/css/bootstrap.min.css';

.App {
  text-align: center;
  caret-color: transparent;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.container {
  max-width: 100% !important;
}

.inputText {
  width: 190px;
  min-height: 28px;
  margin-left: 10px;
  justify-self: flex-start;
  text-align: left;
  padding: 10px;
  border: 1px dotted darkcyan;
  box-shadow: cadetblue 5px 4px;
  caret-color: black;
}


.text-animation {
  display: none;
  position: relative;
  color: black;
  font-size: 25px;
  letter-spacing: 1px;
  line-break: strict;
  line-height: 1;
}

.text-animation .letter {
  display: inline-block;
  opacity: 0;
}

.cursorContainer {
  margin: 0;
  display: flex;
  max-width: 100%;
  align-items: center;
  justify-content: center;
  background: white;
}

.ai {
  /* will-change: transform; */
  background-image: url('assets/codeGif.gif');
  background-size: 475px;
  /* background-blend-mode: difference; */
  background-color: #ff0000;
  /* mix-blend-mode: difference; */
}

.inverse {
  filter: invert(1);
}

.cursor {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  height: 25px;
  background: black;
  z-index: 1;
}

.inputButton {
  padding: 10px;
  margin-top: 10px;
  border: 1px dotted salmon;
  box-shadow: cadetblue 5px 4px;
}

.inputButton:hover {
  opacity: 0.725;
}

.inputButton:focus {
  opacity: 0.725;
}

.inputButtonSecondary {
  background: darkblue;
  color: white;
  box-shadow: darkblue 4px 4px;
}

@keyframes bounce {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(5px, 0, 5px);
  }
}


@keyframes widthChange {
  from {
    height: 154.2px;
    opacity: 0.75
  }

  to {
    height: 0px;
    opacity: 0.5
  }
}


@keyframes widthChangeLoading {
  from {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
  }

  to {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 0px;
  }
}




input {
  -webkit-animation-name: bounce;
  -webkit-animation-duration: 0.2574s;
  -webkit-animation-direction: alternate;
  -webkit-animation-timing-function: cubic-bezier(.5, 0.05, 1, .5);
  -webkit-animation-iteration-count: 2;
}

.mainLogo {
  padding: 5px;
}

.mainLogoDuplicate {
  -webkit-animation-name: widthChange;
  -webkit-animation-duration: 4s;
  -webkit-animation-direction: linear;
  -webkit-animation-iteration-count: infinite;
  padding: 5px;
}

.errorBoxInner {
  transform: translateX(0);
  margin-top: 10px;
  max-width: 525px;
}

.errorBoxAnimated {
  transform: translateX(-100);
  transform: translateY(-510);
  margin-top: 10px;
}

.i-am-centered {
  margin: auto;
  max-width: 300px;
}

.loadingDots {
  /* -webkit-animation-name: widthChangeLoading;
  -webkit-animation-duration: 2.5s;
  -webkit-animation-direction: reverse;
  -webkit-animation-iteration-count: infinite; */
  /* font-size: 90px; */
  font-size: 77.5px;
  /* position: absolute; */
  /* bottom: -14px;
  left: 125px; */
  line-height: 0;
  /* margin-top: -68.5px; */
  /* margin-left: 107px; */
  text-align: left;
}

/* @media (max-width: 995px) {
  .loadingDots {
    font-size: 67.5px;
    line-height: 1.5;
    text-align: left;
  }
}

@media (max-width: 485px) {

  .loadingDots {
    font-size: 47.5px;
    line-height: 1.5;
    text-align: left;
  }
} */


label {
  justify-self: flex-end;
  border: 1px dotted black;
  box-shadow: 5px 4px #666;
  padding: 10px;
}

.bounceRegister {
  display: inline-block;
}

.App-header {
  min-height: 95vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  row-gap: 10px;
  column-gap: 10px;
  font-size: x-small;
  overflow-x: hidden;
}

.mainGrid {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto auto auto;
  gap: 10px;
  padding: 10px;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
} */