    .noscroll {
        overflow: hidden;
    }

    .scroll {
        overflow: auto;
    }

    @media (max-width: 995px) {
        .post {
            text-align: left !important;
        }

        .post-body {
            padding-right: 10px;
            margin-right: 20px;
        }
    }

    html {
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
    }

    html::-webkit-scrollbar {
        display: none;
    }

    body {
        overflow: auto;
    }

    .post {
        background-position-x: -22px;
        background-position-y: 25px;
        background-size: cover;
        background-color: red;
    }

    .post-body {
        transform:
            perspective(800px) rotateY(-8deg);
        transition: transform 1s ease 0s;
        border-radius: 4px;
        background-color: rgb(255, 255, 255);
        /* box-shadow:
            rgba(0, 0, 0, 0.024) 0px 0px 0px 1px,
            rgba(0, 0, 0, 0.05) 0px 1px 0px 0px,
            rgba(0, 0, 0, 0.03) 0px 0px 8px 0px,
            rgba(0, 0, 0, 0.1) 0px 20px 30px 0px; */

        &:hover {
            transform: perspective(800px) rotateY(0deg);
        }
    }

    /* 
    .post-meta {
        transform:
            perspective(1000px) rotateX(4deg) rotateY(-16deg) rotateZ(4deg);
        box-shadow: 24px 16px 64px 0 rgba(0, 0, 0, 0.08);
        border-radius: 2px;
    } */

    svg {
        font-weight: bold;
        height: 25px;
        /* 55 for large screens height  */
        width: 89%;
        margin-top: 15px;
    }

    .tone {
        background-image: url('../../../assets/blood-effect.gif');
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        background-size: cover;
        color: greenyellow;
        background-color: transparent !important;
        -moz-background-clip: text;
        -webkit-background-clip: border-box;
        animation: changeColour 7s infinite reverse;
        text-transform: uppercase;
        font-size: 120px;
        margin: 10px 0;
    }

    .tone-occult {
        background-image: url('../../../assets/smoke-title.gif');
    }

    .tone-general {
        background-image: url('../../../assets/treeGif.gif');
        /* background-position-y: 77px; */
        /* background-size: 20px; */
    }

    .tone-religion {
        background-image: url('../../../assets/treeGif.gif');
        background-size: 575px;
        background-position-y: 57px;
        background-position-x: 76px;
    }

    .tone-code {
        background-image: url('../../../assets/spiralGif.gif');
        /* background-position-y: 77px; */
        background-size: 25px;
    }

    .tone-family {
        background-image: url('../../../assets/blood-effect.gif');
        background-position-y: 57px;
        background-size: 75px;
    }


    .toneCategory {
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }

    @keyframes changeColour {
        0% {
            color: greenyellow;
        }

        50% {
            color: blanchedalmond;
        }

        75% {
            color: greenyellow;
        }

        100% {
            color: lightblue
        }
    }

    .animation {
        /* background: linear-gradient(45deg,
                rgba(255, 255, 255, 0) 45%,
                rgba(255, 255, 255, 0.8) 50%,
                rgba(255, 255, 255, 0) 55%,
                rgba(255, 255, 255, 0) 100%); */
        animation: shine 7s infinite;
        background-size: 200%;
    }

    @keyframes shine {
        0% {
            background-position: -120%;
        }

        10% {
            background-position: 120%;
        }

        100% {
            background-position: 120%;
        }
    }

    @font-face {
        font-family: "Frozen";
        src: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/209981/Ice%20kingdom%20-%20Bold%20-%20Por%20Kustren.woff");
    }

    @media (max-width: 767px) {
        svg {
            height: 12.5px;
        }

        .tone {
            text-align: center;
        }
    }

    .st-btn {
        margin-bottom: 15px;
    }