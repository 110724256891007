hr#bottomHr {
    display: none;
}

#top_articles a {
    color: rgb(15, 1, 18)
}

.home-partition {
    background-image: url('../../../assets/treeGif.gif');
    HEIGHT: 457px;
    background-size: cover;
    /* top: -175px; */
    margin-top: -163px;
    bottom: 0px;
    z-index: 1;
    position: relative;
}

.home-partition {
    height: 0.85px;
    /* transform: matrix(2, 1, 1, 111, 20, 5) skewX(51deg); */
    /* height: 1px; */
    transform: matrix(5, 0, 29, 90, 95, 2) skewX(51deg);
}

.post-partition {
    background-image: url('../../../assets/treeGif.gif');
    /* HEIGHT: 457px; */
    z-index: 1;
    /* transform: matrix(1.0025274265333, 0.0437555, 0, 75, 4, 0);
    border-radius: 25px 49%; */
    transform: matrix(1.025426533377, 0.0437555, 0, 75, 2, 0);
    border-radius: 4px 85%;
    position: relative;
    height: 0.542673845px;
    width: 100% !important;
    margin-bottom: 75px;
    /* margin-top: 27px; */
}

.post-partition2 {
    background-image: url('../../../assets/silver-spiral.gif');
    /* HEIGHT: 457px; */
    z-index: 1;
    /* transform: matrix(1.0274265333, 0.0437555, 0, 75, 4, 0);
    border-radius: 25px 49%; */
    transform: matrix(1.025426533377, 0.0437555, 0, 75, 2, 0);
    border-radius: 1.49575876844485587px 55%;
    position: relative;
    height: 1.5px;
    width: 100% !important;
    margin-bottom: 75px;
    /* margin-top: 27px; */
}


#home-content {
    background-image: linear-gradient(to top, #fad0c4 0%, #ffd1ff 85%);
    transform:
        perspective(2000px) translate3d(0px, -66px, 198px) rotateX(-6deg) scale3d(0.86, 0.75, 1) translateY(50px);
    border-radius: 5px;
    will-change: transform;
    transition: 0.4s ease-in-out transform;
    opacity: 1;
    min-height: 1478.6px;
    display: block;
    /* &:hover {
        transform: scale3d(1, 1, 1);
        opacity: 1;
        transition: all .25s ease-in-out;
    } */
}

@media (max-width: 768px) {
    div.container.body content {
        /*margin-left: 3px !important;*/
        margin-right: 20px
    }

    #content-div {
        padding: 20px !important;
        padding-top: 40px !important;
    }

    #firstHR {
        margin-left: 3px !important;
    }
}

html {
    overflow-y: auto !important;
}

/* 
footer {
    background-color: darkred !important;
} */