.footer {
    padding-top: 50px;
    padding-bottom: 50px;
    width: 100%;
    font-size: small;
    color: #99979c;
    text-align: center;
    /*background-color: #2a2730;*/
    background: #262f3a;
    /*    background-color: #242729;*/
}

.navbar .container {
    display: contents;
}

.navbar-toggler {
    margin-left: 10px;
}

@media (max-width: 388px) {
    ul#horizontal-list li {
        margin: 0px 3px !important;
    }
}

ul#horizontal-list {
    display: inline-table;
    padding: 0;
    margin: 0;
}

ul#horizontal-list li {
    display: inline;
    margin: 0px 8px;
}

@media (max-width: 991px) {
    .dissappear {
        display: none;
    }

    .navbar-collapse {
        flex-basis: 100%;
        position: absolute;
        background-color: aliceblue;
        border: 1px solid slateblue;
        padding: 8px;
        top: 55px;
        margin: 10px 15px;
        flex-grow: 1;
        align-items: center;
    }

    .navbar-nav {
        padding: 12px;
    }

    .nav-item {
        padding: 5px;
    }

    .navbar-nav .dropdown-menu {
        position: static;
        background: green;
    }

    .dropdown-item {
        display: block;
        width: 100%;
        clear: both;
        font-weight: 400;
        text-align: inherit;
        text-decoration: none;
        white-space: nowrap;
        background-color: beige;
        border: 1px dotted grey;
        height: 100%;
        white-space: break-spaces;
    }
}